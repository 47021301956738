/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */

/* @import "~jsoneditor/dist/jsoneditor.min.css"; */
@import "app/core/settings/config-json-editor/jsoneditor.min.css";

a {
    color: #0366d6;
}

code {
    color: #e01a76;
}

.dropdown-button-text-x-large {
    font-size: x-large;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.btn-menu-item {
    color: #ededed !important;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.btn-menu-item:hover {
    background-color: #5757b1 !important;
    color: #ededed !important;
}

.btn-menu-item.active {
    background-color: #5757b1 !important;
    font-weight: bolder;
}

.btn-circle {
    border-radius: 25%;
}

#navAccordion li:first-of-type {
    margin-top: 3px;
}

#navAccordion {
    overflow-y: scroll;
    height: calc(100vh - 6.5rem) !important
}

html {
    position: relative;
    min-height: 100%;
}

body {
    padding-top: 7.5rem;
    margin-bottom: 4.5rem;
}

.nav-link {
    padding-top: 5px;
    padding-bottom: 5px;
    /* padding: 0;*/
    margin: 0;
    text-align: left;
}

.nav-link:hover {
    transition: all 0.4s;
}

.nav-link-collapse:after {
    float: right;
    content: '\f067';
    font-family: 'FontAwesome';
}

.nav-link-show:after {
    float: right;
    content: '\f068';
    font-family: 'FontAwesome';
}

.nav-item a {
    margin-bottom: 0 !important;
    border: none
}

.nav-item ul.nav-second-level {
    padding-left: 0;
}

.nav-item ul.nav-second-level>.nav-item {
    padding-left: 20px;
}

.nav-item ul.nav-third-level {
    padding-left: 0;
}

.nav-item ul.nav-third-level>.nav-item {
    padding-left: 20px;
    list-style-type: none;
}

.custom-menu-item {
    position: relative;
    top: -10px;
    text-transform: uppercase;
    margin-left: 8px;
    /*text-uppercase ml-2*/
}

#logo-container {
    min-width: 260px;
    max-width: 260px;
    height: 105px;
    background-color: #36367a;
}

@media (min-width: 992px) {
    .sidenav {
        position: absolute;
        top: 0;
        left: 0;
        /*width: 230px;*/
        /* width: 260px;*/
        height: calc(100vh - 3.5rem);
        margin-top: 6.5rem;
        background: #36367a;
        /*#343a40;*/
        box-sizing: border-box;
        border-top: 1px solid rgba(0, 0, 0, 0.3);
    }

    .sidenav.expanded {
        width: 260px;
    }

    .sidenav.collapsed {
        width: 93px;
    }

    .navbar-expand-lg .sidenav {
        flex-direction: column;
    }

    .navbar {
        /*z-index: auto;*/
    }

    .page-header-component {
        height: 105px;
    }

    .content-wrapper {
        /*margin-left: 260px;*/
    }

    .content-wrapper-expanded {
        margin-left: 260px;
    }

    .content-wrapper-collapsed {
        margin-left: 93px;
    }

    .footer {
        width: calc(100% - 260px) !important;
        margin-left: 260px;
    }

    .footer-collapsed {
        width: calc(100% - 93px) !important;
        margin-left: 93px;
    }

    .ag-row {
        height: 40px !important;
    }

    .ag-cell {
        line-height: 40px !important;
    }

    /* .datatable-header-cell {
        font-weight:500;
    }*/

    .ngx-datatable {
        color: rgb(51, 51, 51);
    }

    .datatable-icon-down::before,
    .datatable-icon-up::before {
        position: absolute;
        top: 5px;
        right: 20px;
    }

    .hide-icon .datatable-icon-down::before,
    .hide-icon .datatable-icon-up::before {
        display: none;
    }

    .ngx-datatable .datatable-header-cell,
    .ngx-datatable .datatable-body-cell {
        margin: 0;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    div.header-label,
    div.datatable-header-cell-template-wrap {
        text-align: center;
        cursor: pointer;
        word-wrap: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis !important;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        text-transform: uppercase;
        padding: 7px 0;
    }

    div.header-label-nofilter {
        height: inherit;
        text-align: center;
        padding-top: 30px;
    }

    .datatable-body-row>div:hover {
        background-color: #b7b7d9;
    }

    .datatable-row-center>.datatable-header-cell {
        /*background-color: red !important;*/
        border: none;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
    }

    .datatable-row-center>.datatable-header-cell~.datatable-header-cell {
        /*background-color: green !important;*/
        border: none;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
    }

    .datatable-row-center>.datatable-header-cell:last-of-type {
        /*background-color: red !important;*/
        border-left: 1px solid rgba(0, 0, 0, 0.2);
        border: none;
    }

    /* datatable-scroller {
        width: 100% !important;
    }*/

    .digit-row.datatable-body-cell {
        justify-content: right;
    }

    .custom-row.datatable-body-cell {
        justify-content: left;
    }

    .custom-celltext.datatable-body-cell {
        overflow: hidden !important;
        white-space: normal !important;
    }

    .datatable-body-cell .btn-group {
        margin: 1px;
    }
}

@media (min-device-height: 768px

    /* some height of the screen*/
) {
    .layer-wrapper {
        height: 600px
            /* 'some height' divided by ten */
    }
}

.products-expand-row .datatable-body-cell-label {
    text-align: center;
}

.action-buttons {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
    text-overflow: ellipsis !important;
    white-space: normal !important;
    text-align: center !important;
    vertical-align: middle !important;
}

.datatable-header-cell-template-wrap div.input-group {
    visibility: hidden;
    transition-delay: 0.5s;
}

.datatable-header-cell-template-wrap div.input-group.filtered {
    visibility: visible;
}

.datatable-header-cell-template-wrap:hover div.input-group {
    visibility: visible;
    transition-delay: 0.2s;
}

/*loadingelement*/
.ngx-datatable.bootstrap .datatable-body .progress-linear {
    display: block;
    position: relative;
    width: 100%;
    height: 5px;
    padding: 0;
    margin: 0;
    position: absolute;
}

.ngx-datatable.bootstrap .datatable-body .progress-linear .container {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 5px;
    -webkit-transform: translate(0, 0) scale(1, 1);
    transform: translate(0, 0) scale(1, 1);
    background-color: #aad1f9;
}

.ngx-datatable.bootstrap .datatable-body .progress-linear .container .bar {
    transition: all .2s linear;
    -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
    animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
    transition: -webkit-transform .2s linear;
    transition: transform .2s linear;
    background-color: #106cc8;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
}

.datatable-body-cell-label .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
}

.datatable-body-cell-label .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
}

.datatable-body-cell-label .btn-outline-primary:hover .fa {
    color: inherit;
    background-color: inherit;
}

.datatable-body-cell-label .btn-outline-info:hover .fa {
    color: inherit;
    background-color: inherit;
}

.datatable-body-cell-label .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
}

.datatable-body-cell-label .btn-outline-danger:hover .fa {
    color: inherit;
    background-color: inherit;
}

.isRecent {
    color: #0366d6;
}

/*.wraptext*/
.datatable-body-cell-label {
    overflow-wrap: anywhere;
}

.progressbar>.datatable-body-cell-label {
    width: 100%;
    text-align: center;
}

.popup-error {
    /* color: red;
    background-color: red*/
}

.modal-header button.close {
    margin: 0;
}

.card-header h3 {
    padding: 0;
    margin: 0;
}

.datatable-body-cell,
.datatable-header-cell {
    overflow-y: visible !important;
    overflow-x: visible !important;
}

.ngx-datatable {
    overflow-x: visible !important;
    overflow-y: visible !important;
}

/*.progress-bar {
    background-color: black !important;
}*/


.datatable-body-cell {
    /*background-color: #007bff;*/
}

.bg-custom-success {
    background-color: rgba(91, 185, 41, 0.50) !important;
}

.bg-custom-danger {
    background-color: rgba(255, 47, 0, 0.85) !important;
}

.bg-custom-accepted {
    background-color: rgba(16, 108, 200, 0.25) !important;
}

.bg-custom-validated {
    background-color: lightgreen !important;
}

.datatable-header-cell,
.datatable-header {
    /*   overflow: visible !important;*/
    overflow: hidden !important;
}

span.datatable-icon-sort-unset.sort-btn {
    display: none !important;
}

.datatable-header {
    border-radius: 7px 7px 0px 0px !important;
    margin-bottom: 0px !important;
    /*    border: 1px solid rgba(0, 0, 0, 0.2)*/
    border-top: 1px solid gainsboro;
    border-left: 1px solid gainsboro;
    border-right: 1px solid gainsboro;
    border-bottom: none !important;
    background-color: whitesmoke;
    /*#6c757d #ccc*/
    color: darkslateblue;
    text-transform: capitalize !important;
    font: bolder;
    /*    height: 60px !important;*/
}

.datatable-body {
    border-left: 1px solid gainsboro;
    border-right: 1px solid gainsboro;
}

.datatable-header-cell {
    height: 92px !important;
}

.datatable-footer {
    border-radius: 0 0 7px 7px;
    margin-top: -5px !important;
    background-color: gainsboro !important;
    color: #505050 !important;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
}

.datatable-body .empty-row {
    font-size: 18px !important;
}

.datatable-body-cell {
    /* border-bottom: 1px solid gainsboro;
    border-left: 1px solid gainsboro;
    border-right: 1px solid gainsboro;*/
    line-height: 1.42857143;
    /*padding: 8px;*/
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    min-height: 66px !important;
}

.datatable-row-center>.datatable-body-cell {
    /*background-color: red !important;*/
    border-left: none !important;
    /*border: none;*/
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.datatable-row-center>.datatable-body-cell~.datatable-body-cell {
    /*background-color: green !important;*/
    /*border: none;*/
    border-right: 1px solid gainsboro;
}

.datatable-row-center>.datatable-body-cell:last-of-type {
    /*background-color: red !important;*/
    border-right: none !important;
    /*border-left: 1px solid rgba(0, 0, 0, 0.2);
        border: none;*/
}


/* PRINTER TABLE**/
.width100 {}

.datatable-body-cell.width100 .datatable-body-cell-label {
    width: 100% !important
}

.printer-table .datatable-body-row>div:hover {
    background-color: #8383BD;
}

.printer-table .datatable-footer {
    background-color: #f4f4f4 !important;
    border: 1px solid gainsboro !important;
    /*border-top: none;*/
    margin-top: -6px !important;
}

.printer-table .page-count {
    display: none;
}

.double-table .page-count {
    min-width: 130px !important;
    padding: 0 3px 0 5px !important;
    font-size: 16px !important;
}

.printer-table .datatable-body-cell {}

.printer-table .printerName {
    /* background-color: red !important;*/
    border-left: none !important;
    /*border: none;*/
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

/*.printer-table .datatable-row-center > .datatable-body-cell {*/
/*background-color: red !important;*/
/*border-left: none !important;*/
/*border: none;*/
/*border-right: 1px solid rgba(0, 0, 0, 0.2);
    }*/

.printer-table .datatable-row-center>.datatable-body-cell~.datatable-body-cell {
    /*background-color: green !important;*/
    /*border: none;*/
    border-right: 1px solid gainsboro;
}

.printer-table .datatable-row-center>.datatable-body-cell:last-of-type {
    /*background-color: red !important;*/
    border-right: none !important;
    /*border-left: 1px solid rgba(0, 0, 0, 0.2);
        border: none;*/
}

/* PRINTER TABLE**/

.datatable-pager a {
    /*color: red !important;*/
    font-size: 18px;
    color: #505050 !important
        /*#36367a!important;*/
}

.datatable-pager .active a {
    /*color: red !important;*/
    color: #fff !important
        /*#36367a!important;*/
}

.datatable-pager a:hover {
    /*color: red !important;*/
    color: #fff !important
        /*#36367a!important;*/
}

.slider-tick-label-container {
    color: #838383;
    font-weight: 700;
    font-size: 16px;
}

.slider-handle {
    background-image: linear-gradient(to bottom, #21e336 0, #212d29 100%);
}

.slider-selection.tick-slider-selection {
    background-image: linear-gradient(to bottom, #4dd93a 0, #76f29b 100%);
}

.slider-tick.in-selection {
    background-image: linear-gradient(to bottom, #7ef97b 0, #82de90 100%);
}

.progress {
    background-color: #273f57;
}

.ngb-toasts {
    position: fixed !important;
    bottom: 0 !important;
    top: unset !important;
    right: 0 !important;
    margin: 10px !important;
    z-index: 1400 !important;
}

.toast:host {
    position: fixed !important;
    bottom: 0 !important;
    top: unset !important;
    right: 0 !important;
    z-index: 1400 !important;
    margin: 10px !important;
}

.toast {
    margin-top: 10px !important;
    z-index: 1400 !important;
}

.usernameinfo {
    color: #4f4f4f;
    margin: auto !important;
    font-size: 18px;
    /*2rem;*/
    /*font-family: Roboto;*/
    /*  font-style: normal;
  font-weight: 500;*/

    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    /*width: 85px;*/
    /*width:55px;*/
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin: 0 5px 0 5px;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
}

.violet-text {
    color: #8383BD !important;
}

.big-checkbox {
    width: 30px;
    height: 30px;
    /*margin-left: -40px;*/
    margin-top: 0px;
}

.big-switch {
    width: 50px !important;
    height: 30px;
    /*margin-left: -40px;*/
    margin-top: 0px;
}

.plc-indicator {
    width: 35px;
    height: 35px;
    -moz-border-radius: 35px;
    -webkit-border-radius: 35px;
    border: 7px;
    border-color: #6c757d;
    border-radius: 35px;
    border-style: double;
}

.btn-outline-info {
    color: #8383BD !important;
    border-color: #8383BD !important;
}

.btn-outline-info:hover {
    background-color: #8383BD !important;
    color: #fff !important;
}

.btn-outline-danger {
    color: #EB5757 !important;
    border-color: #EB5757 !important;
}

.btn-outline-danger:hover {
    background-color: #EB5757 !important;
    color: #fff !important;
}

.btn-info {
    background-color: #8383BD !important;
    color: #fff !important;
}

.btn-info.active {
    background-color: #484872 !important;
    color: #fff !important;
}

.btn-info:hover {
    color: #fff !important;
    /*font-weight: bolder;*/
    background-color: #8d83bd !important;
    border-color: #8383BD !important;
}

.btn-danger {
    background-color: #EB5757 !important;
    color: #fff !important;
}

.btn-danger:hover {
    color: #fff !important;
    border-color: #c82333 !important;
    background-color: #c82333 !important;
}

.btn-success {
    background-color: #27AE60 !important;
    color: #fff !important;
}

.btn-success:hover {
    color: #fff !important;
    border-color: #27AE60 !important;
    background-color: #1e7e34 !important;
}

.bg-success {
    background-color: #27AE60 !important;
}

.bg-danger {
    background-color: #EB5757 !important;
}

.bg-success code,
.bg-danger code {
    color: #fff;
}

#codeTable .bg-success .datatable-body-cell,
#codeTable .bg-danger .datatable-body-cell {
    color: #fff !important;
}


.custom {
    bottom: 0px !important;
}

.code-width {
    width: 232px !important;
}

.code-filter-block {
    float: left;
    display: block;
    margin-right: 4px;
}

.code-filter-header {
    background: #EDEDED !important;
    border-radius: 4px 4px 0px 0px !important;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
}

.header-badge {
    font-weight: 500;
    font-size: 36px;
    color: #333333;
}

.main-text-color {
    color: #36367a;
}

.progress {
    background-color: #36367A;
}

.custom-btn-prepend {
    color: #495057;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    display: flex;
    width: 200px;
}

.custom-btn-prepend ::after,
.custom-btn-prepend ::before {
    box-sizing: border-box;
}

.custom-btn-prepend2 {
    color: #495057;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    display: flex;
    width: 136px;
    height: 64px;
    white-space: normal;
    line-height: 1.0;
    text-align: center;
    text-transform: uppercase;
}

.custom-btn-var {
    border: 1px solid #ced4da !important;
    padding: 8px 0px !important;
    vertical-align: middle !important;
    /*line-height: 50px;*/
    /*width: 130px;*/
    /*display: flex;*/
    align-items: center;
    text-overflow: ellipsis !important;
    white-space: normal !important;
    text-align: center !important;
}

customPopover {
    z-index: 0;
}

.customPopover2 {
    max-width: 500px;
    min-width: 500px;
    font-size: 18px;
    border-radius: 8px;
    /*background-color: */
    z-index: 0;
}

.customTooltip {
    /*position: fixed!important;*/
}

input:disabled,
select:disabled,
select option:hover {
    /*color: #fff !important;*/
    background-color: #B9B9D3
        /*#B8B8EE*/
         !important;
}

.btn-icon {
    height: 64px;
    text-align: left;
    padding: 4px 8px 4px 8px;
    border-radius: 7px;
}

.btn-icon-label {
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    display: block;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    word-wrap: normal;
    white-space: normal;
    overflow: inherit;
    text-overflow: inherit;
}

.btn-agg {
    height: 64px;
    padding-top: 15px;
}

.confirm1 {
    position: absolute !important;
    top: 30% !important;
    left: 0% !important;
}

.confirm2 {
    position: absolute !important;
    top: 40% !important;
    left: 10% !important;
}

.confirm3 {
    position: absolute !important;
    top: 10% !important;
    left: 15% !important;
}

.confirm4 {
    position: absolute !important;
    top: 20% !important;
    left: 20% !important;
}

.confirm5 {
    position: absolute !important;
    top: 20% !important;
    left: -20% !important;
}

.confirm6 {
    position: absolute !important;
    top: 30% !important;
    left: -15% !important;
}

.confirm7 {
    position: absolute !important;
    top: 0% !important;
    left: -10% !important;
}

.scroll-to-top {
    position: fixed;
    bottom: 15px;
    right: 15px;
    opacity: 0;
    transition: all .001s linear;
}

.show-scrollTop {
    opacity: 1;
    transition: all .001s linear;
}

.form-with-noborders {
    border-top: 0px;
    border-bottom: 0px;
}

.label-edit-width {
    min-width: 150px;
}

.label-edit-width-180 {
    min-width: 180px;
}

.label-edit-width-190 {
    min-width: 190px;
}

.label-edit-width-200 {
    min-width: 200px;
}

.label-edit-width-210 {
    min-width: 210px;
}

.label-edit-width-255 {
    min-width: 255px;
}

.hide-dropdown .dropdown-toggle::after {
    display: none;
}

.custom-img-collapsed {
    max-width: 150px !important;
    height: auto !important;
}

.custom-img-expanded {
    max-width: 400px !important;
    height: auto !important;
}

.btn-close {
    margin: auto !important;
}

/**
 * Progress bar animations
 */
@keyframes query {
    0% {
        opacity: 1;
        transform: translateX(35%) scale(0.3, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(0, 1);
    }
}

@keyframes color-change {
    0% {
        color: rgb(224, 168, 0);
    }

    50% {
        color: rgb(224, 188, 0);
    }

    100% {
        color: rgb(224, 168, 0);
    }
}

@keyframes color-change-info {
    0% {
        color: #17a2b8;
    }

    25% {
        color: #27AE60;
    }

    50% {
        color: #ffc107;
    }

    75% {
        color: #27AE60;
    }

    100% {
        color: #17a2b8;
    }
}

#Footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #f4f4f4
        /*#f5f5f5*/
    ;
    line-height: 3.5rem;
    /*background-color: #ccc;*/
}


html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 60px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: #f4f4f4;
    overflow: visible !important;
}

.afu-select-btn {
    margin: 0px 0px 0px 3px !important;
}

.afu-valid-file {
    margin: 0px !important;
}

#default {
    margin: 0;
}

.afu-select-btn,
.afu-reset-btn,
.afu-upload-btn {
    /*margin: 0px 0px 0px 3px !important;*/
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem;
}

.afu-valid-file {
    margin: 0px !important;
}

.afu-reset-btn {
    background-color: #EB5757 !important;
}

.afu-reset-btn :hover {
    color: #fff !important;
    border-color: #c82333 !important;
    background-color: #c82333 !important;
}

.custom-file-uploader a {
    display: none;
}

.mediaText1 {
    font-size: 1.0rem;
}

.aggregation-counter {
    font-size: 140px;
    height: 140px;
    margin-top: -20px;
    /*color: red;*/
}

@media (min-width: 1200px) {
    .aggregation-counter {
        font-size: 160px;
        height: 160px;
        margin-top: -40px;
        /*color: green;*/
    }
}

@media (min-width: 1400px) {
    .aggregation-counter {
        font-size: 190px;
        height: 190px;
        margin-top: -70px;
        /*color: yellow;*/
    }
}

.dark-theme .page-header-component,
.dark-theme #logo-container,
.dark-theme .sidenav,
.dark-theme #Footer,
.dark-theme #login-container {
    background-color: #343a40 !important
}

.dark-theme #Footer,
.dark-theme .usernameinfo {
    color: #ededed !important;
}